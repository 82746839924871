import React, { useCallback, useEffect, useState } from 'react';
import { ChakraBox, SEO } from '../components';
import { Background, Product } from '../lib/types';
import { navigate } from 'gatsby';
import { useAppDispatch } from '../redux/hooks';
import { changeBackground, changeCurrentLogo } from '../redux/reducers/ui';
import { PageLayout, Section } from '../widgets';
import Arteria from '../lib/core';
import { ARTERIA_PHASE } from '../lib/env';
import '../assets/fonts/jakarta.css';

// UI – style imports
import {
  Box,
  Icon,
  Center,
  SimpleGrid,
  Text,
  Heading,
  Input,
  Textarea,
  Button,
  VStack,
} from '@chakra-ui/react';

import { motion } from 'framer-motion';

const blueRock = (
  <svg width="1062" height="706" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M83.9793 47.0418c.3167-1.5461 1.513-2.7615 3.054-3.1027L284.193.289439c.848-.187836 1.735-.094278 2.525.266526L382.209 44.1389c.521.238 1.088.3611 1.661.3611h196.694c.29 0 .579-.0315.862-.0939L781.888.190037c.568-.1251866 1.156-.1251869 1.724 0L982.913 44.1501c1.018.2243 1.907.8368 2.48 1.7071l75.127 114.1538c.62.948.82 2.116.53 3.213L919.811 702.403c-.474 1.81-2.14 3.05-4.011 2.984L201.7 680.06c-1.086-.038-2.11-.517-2.836-1.326L1.34511 458.5c-.846106-.944-1.195128-2.232-.940815-3.474L83.9793 47.0418Z"
      fill="#093266"
    />
  </svg>
);

const rightMountain = (
  <svg width="1040" height="494" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m981.28 13.24-22.69 1.89L922.67 0 795.99 103.99l-28.36-9.45-24.58 9.45L692 71.85l-98.31 65.16h-30.26l-136.13 65.3-96.42 60.5-221.22 88.86L0 493.48h1039.9V103.99l-58.62-90.75Z"
      fill="#356199"
    />
  </svg>
);

const leftMountain = (
  <svg width="1388" height="494" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m0 257.65 111.5-16.67 58 20L396.13 86.97l51.05-20.79 90.75 22.68 73.74-22.68 102.1-45.38L740.24 0l83.19 35.92 26.47 47.27 51.05 23.64 49.16 40.65 85.08 35.92 77.52 9.45 122.9 64.29L1388 493.48H0V257.65Z"
      fill="#89A1C0"
    />
  </svg>
);

const ship = (
  <svg viewBox="0 0 74 196" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_454_1165)">
      <path
        d="M72.1304 60.3349C72.1253 60.1264 72.0483 59.9259 71.9124 59.7673C71.7765 59.6088 71.5899 59.5017 71.3841 59.4641L59.5513 54.0426L59.5513 22.4832C59.4959 16.5553 57.0956 10.8889 52.8725 6.71664C48.6493 2.54434 42.9449 0.203613 37 0.203613C31.0551 0.203613 25.3507 2.54434 21.1275 6.71664C16.9044 10.8889 14.5041 16.5553 14.4487 22.4832L14.4487 54.0348L2.49083 59.4641C2.11769 59.7135 1.74456 60.0856 1.74456 60.4577L0 79.216C0 79.8374 0.373136 80.4589 0.99633 80.5797H1.24639C1.48492 80.5737 1.71687 80.5005 1.91534 80.3684C2.11382 80.2363 2.27068 80.0509 2.36775 79.8335L7.84757 65.5481L14.4566 63.7383V64.5506C14.4581 66.0185 15.0436 67.4259 16.0844 68.4638C17.1253 69.5018 18.5366 70.0856 20.0087 70.0872H31.2281L35.2642 93.0066C35.3271 93.2893 35.4848 93.5421 35.7112 93.7233C35.9377 93.9045 36.2193 94.0032 36.5097 94.0032C36.8 94.0032 37.0816 93.9045 37.3081 93.7233C37.5345 93.5421 37.6922 93.2893 37.7551 93.0066L42.639 70.0872H54.0011C55.4731 70.0856 56.8844 69.5018 57.9253 68.4638C58.9662 67.4259 59.5517 66.0185 59.5532 64.5506V63.7383L66.1524 65.552L71.6342 79.7147C71.7592 80.2115 72.2574 80.4589 72.7556 80.4589H73.0056C73.6288 80.3342 74.002 79.7147 74.002 79.0952L72.1304 60.3349Z"
        fill="#CE509D"
      />
      <path
        d="M49.285 33.5601H25.4512C24.3558 33.5585 23.3058 33.1239 22.5312 32.3516C21.7567 31.5792 21.3209 30.5321 21.3193 29.4398V22.8533C21.3193 18.6089 23.0102 14.5383 26.0199 11.537C29.0296 8.53571 33.1117 6.84961 37.3681 6.84961C41.6245 6.84961 45.7065 8.53571 48.7162 11.537C51.726 14.5383 53.4168 18.6089 53.4168 22.8533V29.4398C53.4163 30.5321 52.9811 31.5795 52.2067 32.352C51.4324 33.1246 50.3823 33.5591 49.2869 33.5601H49.285Z"
        fill="#F5DCEB"
      />
      <path
        d="M27.4748 21.3689C27.2625 21.3684 27.0591 21.2841 26.909 21.1344C26.7589 20.9847 26.6743 20.7818 26.6738 20.5702C26.6738 18.6221 27.6702 16.3661 29.3385 14.5466C31.1905 12.5284 33.5055 11.4141 35.8537 11.4141C35.9624 11.4087 36.071 11.4254 36.173 11.4632C36.2749 11.501 36.3681 11.559 36.4469 11.6338C36.5257 11.7086 36.5884 11.7986 36.6313 11.8983C36.6742 11.9979 36.6963 12.1053 36.6963 12.2138C36.6963 12.3222 36.6742 12.4296 36.6313 12.5293C36.5884 12.629 36.5257 12.7189 36.4469 12.7937C36.3681 12.8685 36.2749 12.9265 36.173 12.9643C36.071 13.0021 35.9624 13.0188 35.8537 13.0135C33.2965 13.0135 31.4152 14.6499 30.5204 15.6259C29.1354 17.1376 28.2758 19.0312 28.2758 20.5702C28.2758 20.782 28.1914 20.9852 28.0412 21.1349C27.891 21.2847 27.6872 21.3689 27.4748 21.3689V21.3689Z"
        fill="#CE509D"
      />
      <path
        d="M32.404 39.2388H22.5638C21.8754 39.2388 21.3174 39.7952 21.3174 40.4817V50.2943C21.3174 50.9807 21.8754 51.5372 22.5638 51.5372H32.404C33.0924 51.5372 33.6504 50.9807 33.6504 50.2943V40.4817C33.6504 39.7952 33.0924 39.2388 32.404 39.2388Z"
        fill="#F5DCEB"
      />
      <path
        d="M38.5087 64.1763C40.5036 64.1763 42.1208 62.5636 42.1208 60.5742C42.1208 58.5849 40.5036 56.9722 38.5087 56.9722C36.5137 56.9722 34.8965 58.5849 34.8965 60.5742C34.8965 62.5636 36.5137 64.1763 38.5087 64.1763Z"
        fill="#F5DCEB"
      />
      <path
        d="M47.7274 64.1767C49.7224 64.1767 51.3396 62.5641 51.3396 60.5747C51.3396 58.5853 49.7224 56.9727 47.7274 56.9727C45.7325 56.9727 44.1152 58.5853 44.1152 60.5747C44.1152 62.5641 45.7325 64.1767 47.7274 64.1767Z"
        fill="#F5DCEB"
      />
      <path
        d="M28.6043 44.144H26.3733C26.0428 44.144 25.7258 44.275 25.492 44.5081C25.2583 44.7412 25.127 45.0573 25.127 45.3869C25.127 45.7166 25.2583 46.0327 25.492 46.2658C25.7258 46.4989 26.0428 46.6298 26.3733 46.6298H28.6141C28.9447 46.6298 29.2617 46.4989 29.4954 46.2658C29.7292 46.0327 29.8605 45.7166 29.8605 45.3869C29.8605 45.0573 29.7292 44.7412 29.4954 44.5081C29.2617 44.275 28.9447 44.144 28.6141 44.144H28.6043Z"
        fill="#CE509D"
      />
    </g>
    <circle cx="36.5" cy="111.5" r="2.5" fill="#CE509D" />
    <circle cx="36.5" cy="137.5" r="2.5" fill="#CE509D" />
    <circle cx="36.5" cy="193.5" r="2.5" fill="#CE509D" />
    <defs>
      <clipPath id="clip0_454_1165">
        <rect width="74" height="94" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const astronaut = (
  <svg
    width="72"
    height="192"
    viewBox="0 0 72 192"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_454_1521)">
      <path
        d="M32.9556 57.3094C45.2412 57.3094 55.2007 47.2996 55.2007 34.952C55.2007 22.6044 45.2412 12.5947 32.9556 12.5947C20.6699 12.5947 10.7104 22.6044 10.7104 34.952C10.7104 47.2996 20.6699 57.3094 32.9556 57.3094Z"
        fill="white"
      />
      <path
        d="M53.1328 54.9494H13.3718C13.3718 54.9494 0 54.9494 0 70.5499V115.124C0 115.124 0 121.806 6.50051 121.806C13.001 121.806 13.0093 115.124 13.0093 115.124V75.3774H15.2338V183.835C15.2338 183.835 15.4233 192.008 23.4068 192.008C31.3903 192.008 31.5798 183.835 31.5798 183.835V120.879H34.3646V183.653C34.3646 183.653 34.1751 191.826 42.5376 191.826C50.9001 191.826 50.7106 183.653 50.7106 183.653V75.3774H52.9351V115.306C52.9351 115.306 52.9351 121.988 59.4439 121.988C65.9526 121.988 65.9526 115.306 65.9526 115.306V70.7321C66.4964 54.9412 53.1246 54.9412 53.1246 54.9412L53.1328 54.9494Z"
        fill="#F68E20"
      />
      <path
        d="M48.2141 34.9438C48.2141 37.8502 47.3655 40.6987 45.7507 43.1166C44.1359 45.5345 41.8454 47.4225 39.1513 48.5403C36.4654 49.6582 33.5076 49.948 30.6487 49.385C27.7981 48.8219 25.1698 47.4225 23.1101 45.3606C21.0504 43.3071 19.6498 40.6822 19.0813 37.8337C18.5128 34.9769 18.8094 32.0207 19.9216 29.3379C21.0339 26.655 22.9289 24.353 25.3511 22.7466C27.7733 21.1319 30.6158 20.279 33.5324 20.279H33.623C41.9196 20.279 48.2059 27.1767 48.2059 34.9438H48.2141Z"
        fill="#F68E20"
      />
      <path
        d="M68.1277 0.79489C63.3821 -1.64786 57.6313 1.82995 57.6313 7.15432C57.6313 9.90344 59.1555 12.6029 62.3522 13.845V25.6281C61.8084 25.4873 61.2564 25.4128 60.6962 25.4211H59.6993C56.5685 13.3481 45.8414 4.77781 33.516 4.77781C21.1905 4.77781 10.4799 13.3564 7.37384 25.4211H6.36045C4.67971 25.4211 3.06488 26.0918 1.87024 27.2759C0.675592 28.46 0 30.0747 0 31.7557V38.1482C0 39.8375 0.675592 41.4522 1.87024 42.6363C3.06488 43.8287 4.67971 44.4994 6.36045 44.5077H6.4346V58.2533C6.4346 58.717 6.57467 59.1642 6.83007 59.5451C7.08548 59.926 7.44799 60.2323 7.86818 60.4062L8.1483 60.5304C8.42842 60.6712 8.70855 60.7871 8.83213 60.8368C16.5438 64.2815 24.898 66.0949 33.3429 66.1446C41.8208 66.0784 50.1998 64.2981 57.9773 60.9113C58.2575 60.812 58.5376 60.7043 58.8095 60.5801L59.139 60.4393C59.5757 60.2655 59.9547 59.9674 60.2183 59.5782C60.482 59.189 60.622 58.7336 60.622 58.2616V44.5077H60.6962C62.3769 44.5077 63.9918 43.8287 65.1782 42.6363C66.3646 41.4439 67.0402 39.8375 67.0484 38.1482V13.9443C68.4902 13.4972 69.7508 12.5946 70.6488 11.3691C71.5386 10.1436 72.0165 8.66965 72.0082 7.1626C72.0082 5.846 71.654 4.56252 70.9701 3.43637C70.2863 2.31022 69.3059 1.39937 68.1359 0.80317L68.1277 0.79489ZM33.5407 54.3283C30.9701 54.3697 28.4161 53.8895 26.035 52.9372C23.654 51.9767 21.4789 50.5607 19.6498 48.7556C17.8208 46.9504 16.3625 44.8058 15.3738 42.4375C14.3852 40.0693 13.8744 37.5272 13.8744 34.9602C13.8744 32.3933 14.3852 29.8512 15.3738 27.4829C16.3625 25.1147 17.8208 22.9618 19.6498 21.1649C21.4789 19.3598 23.654 17.9438 26.035 16.9833C28.4161 16.0227 30.9701 15.5507 33.5407 15.5921C44.8363 15.5921 52.9104 24.8415 52.9104 34.9602C52.9104 40.0942 50.8671 45.021 47.2338 48.6562C43.6004 52.2913 38.6735 54.3366 33.5407 54.3449V54.3283Z"
        fill="#F68E20"
      />
    </g>
    <defs>
      <clipPath id="clip0_454_1521">
        <rect width="72" height="192" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const star = (
  <svg width="51" height="75" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M25.01 0C21.76 26.55 22.72 34.37 0 37.32c22.33 3.62 22.65 11.8 25.01 37.32 3.11-25.67 2.5-33.36 25.01-37.32C27.34 34.41 28.1 26.52 25.01 0Z"
      fill="#CE509D"
    />
  </svg>
);

const imagesList = [
  {
    svg: star,
    viewBox: '0 0 56 83',
    width: '71.8px',
    bottom: '47%',
    right: '81%',
    hasMotion: true,
    animate: { x: 250, y: 250 },
  },
  {
    svg: star,
    viewBox: '0 0 56 83',
    width: '71.8px',
    bottom: '75%',
    right: '90%',
    hasMotion: true,
    animate: { x: 220, y: -200 },
  },
  {
    svg: star,
    viewBox: '0 0 56 83',
    width: '71.8px',
    bottom: '90%',
    right: '28%',
    hasMotion: true,
    animate: { x: 250, y: 250 },
  },

  {
    svg: star,
    viewBox: '0 0 56 83',
    width: '71.8px',
    bottom: '30%',
    right: '38%',
    hasMotion: true,
    animate: { x: 100, y: 450 },
  },
  {
    svg: star,
    viewBox: '0 0 56 83',
    width: '29px',
    bottom: '62%',
    right: '25%',
    hasMotion: true,
    animate: { x: 110, y: 380 },
  },
  {
    svg: star,
    viewBox: '0 0 56 83',
    width: '29px',
    bottom: '73%',
    right: '71%',
    hasMotion: true,
    animate: { x: 40, y: 200 },
  },
  {
    svg: star,
    viewBox: '0 0 56 83',
    width: '29px',
    bottom: '13%',
    right: '96%',
    hasMotion: true,
    animate: { x: 200, y: 200 },
  },
];

const ContactPage = () => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [subject, setSubject] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(changeBackground(Background.Main));
    dispatch(changeCurrentLogo({ product: Product.Arteria }));
  }, [dispatch]);

  const onPress = useCallback(async () => {
    const arteria = new Arteria({ phase: ARTERIA_PHASE });
    setIsLoading(true);
    try {
      await arteria.contactUs.create({
        body: message,
        destination: email,
        name: name,
        title: subject,
      });
      await navigate('/bridge');
      setName('');
      setEmail('');
      setMessage('');
      setSubject('');
    } catch (ignore) {
    } finally {
      setIsLoading(false);
    }
  }, [message, email, name, subject]);

  return (
    <>
      <SEO
        title="Contact Us"
        description="Emerging markets are complicated, we're not"
        image="../assets/images/logos/arteria.svg"
      />
      <PageLayout isOverflowActive>
        <Section>
          {imagesList.map((item, index) => (
            <ChakraBox
              key={index}
              animate={item.hasMotion ? item.animate : { x: 0, y: 0 }}
              transition={{ duration: '3' }}
              bottom={item.bottom}
              right={item.right}
              pos="absolute"
              zIndex="80"
            >
              <Icon key={index} w={item.width} h="20%" viewBox={item.viewBox}>
                {item.svg}
              </Icon>
            </ChakraBox>
          ))}
          <Center h="100%" w="100vw">
            <SimpleGrid
              columns={2}
              spacingX="13vw"
              spacingY={8}
              zIndex={100}
              mt="-10vh"
            >
              <VStack spacing={8} align="start">
                <Heading as="h2" size="3xl" color={'white'}>
                  Get in touch
                </Heading>
                <Heading
                  fontWeight={700}
                  color="white"
                  fontSize="1.25rem"
                  letterSpacing="0.05em"
                  lineHeight="1.875rem"
                >
                  How can we help you?
                </Heading>
                <Text
                  fontSize="1rem"
                  letterSpacing="0.05em"
                  lineHeight="1.25rem"
                  fontWeight="bold"
                  color="brandAccent.400"
                >
                  integrations@arteria.xyz
                </Text>
              </VStack>
              <VStack spacing={6} align="start">
                <Input
                  placeholder="Name"
                  size="md"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  bg="#7393B3"
                  borderColor="#7393B3"
                  color="white"
                  _placeholder={{ opacity: 1, color: 'white' }}
                />
                <Input
                  placeholder="Email Adress"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  size="md"
                  bg="#7393B3"
                  borderColor="#7393B3"
                  color="white"
                  _placeholder={{ opacity: 1, color: 'white' }}
                />
                <Input
                  placeholder="Subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  size="md"
                  bg="#7393B3"
                  borderColor="#7393B3"
                  color="white"
                  _placeholder={{ opacity: 1, color: 'white' }}
                />
                <Textarea
                  placeholder="Your message"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  h={40}
                  w="100%"
                  bg="#7393B3"
                  resize="none"
                  borderColor="#7393B3"
                  color="white"
                  _placeholder={{ opacity: 1, color: 'white' }}
                />
                <Button
                  w="60%"
                  colorScheme="brandAccent"
                  onClick={onPress}
                  borderRadius="4px"
                  isLoading={isLoading}
                >
                  SEND MESSAGE
                </Button>
              </VStack>
            </SimpleGrid>
            <Box position="absolute" bottom="16%" w="74%" h="68%">
              <Icon w="100%" h="100%" viewBox="0 0 1062 706">
                {blueRock}
              </Icon>
            </Box>
            <Box position="absolute" bottom="25%" width="3vw" zIndex="90">
              <motion.div animate={{ y: -950 }} transition={{ duration: 3 }}>
                <Box>
                  <Icon w="100%" h="100%" viewBox="0 0 74 196">
                    {ship}
                  </Icon>
                </Box>
              </motion.div>
            </Box>
          </Center>
          <Box position="absolute" bottom="42vh" w="3vw" left="15.5vw">
            <motion.div animate={{ y: -200 }} transition={{ duration: 3 }}>
              <Box>
                <Icon w="100%" h="100%" viewBox="0 0 74 196">
                  {astronaut}
                </Icon>
              </Box>
            </motion.div>
          </Box>
          <Box position="absolute" bottom="0%" w="100vw" right="30vw">
            <motion.div animate={{ x: 300 }} transition={{ duration: 3 }}>
              <Box>
                <Icon w="100%" h="100%" viewBox="0 0 1388 494">
                  {leftMountain}
                </Icon>
              </Box>
            </motion.div>
          </Box>
          <Box position="absolute" bottom="0%" w="90vw" left="40vw">
            <motion.div animate={{ x: -300 }} transition={{ duration: 3 }}>
              <Box>
                <Icon w="100%" h="100%" viewBox="0 0 1040 494">
                  {rightMountain}
                </Icon>
              </Box>
            </motion.div>
          </Box>
        </Section>
      </PageLayout>
    </>
  );
};

export default ContactPage;
